frappe.provide('webshop')

$(document).ready(function() {
	webshop.ProductGrid = class {

		constructor(options) {
			Object.assign(this, options);
	
			if (this.preference !== "Grid View") {
				this.products_section.addClass("hidden");
			}
	
			this.products_section.empty();
			this.make();
		}

		make() {
			let me = this;
			let $product_wrapper = this.products_section; // Assuming this.products_section is your wrapper element
			$product_wrapper.empty(); // Clear existing content before appending new items
		
			// Add a loading indicator background to the container
			const $gridArea = $('#products-grid-area'); // Select the container with ID 'products-grid-area'
			$gridArea.css({
				'background-image': 'url("/assets/ntpt_erpnext_app/images/loading1.gif")',
				'background-size': 'cover',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'min-height': '400px', // Adjust based on your layout
			});
		
			// Preprocess items to extract necessary information and prepare for sorting
			const processedItems = this.items.map(item => {
				const seriesMatch = item.item_name.match(/\d+/); // Match any number in item_code
				const seriesNumber = seriesMatch ? parseInt(seriesMatch[0]) : Number.MAX_SAFE_INTEGER; // Default to a large number if no match
		
				return {
					...item,
					seriesNumber, // Add series number to item
				};
			});
		
			// Sort items based on the extracted series number
			processedItems.sort((a, b) => {
				return a.seriesNumber - b.seriesNumber; // Sort by series number in ascending order
			});
		
			// Fetch item counts for all items in parallel
			const itemCountPromises = processedItems.map(item => {
				return frappe.call({
					method: "ntpt_erpnext_app.ntpt_erpnext_app.doctype.cart.cart.get_cart_item_counts",
					args: { item_codes: [item.item_code] } // Use single item_code in an array for backward compatibility
				}).then(r => {
					item.item_count = r.message && r.message[item.item_code] ? r.message[item.item_code] : 0; // Set item count
					return item; // Return the updated item
				});
			});
		
			// Wait for all counts to be fetched
			Promise.all(itemCountPromises).then(itemsWithCounts => {
				// Clear loading background after items are loaded
				$gridArea.css('background-image', '');
		
				// Render items with updated counts
				itemsWithCounts.forEach(item => {
					let title = item.web_item_name || item.item_name || item.item_code || "";
					title = title.length > 90 ? title.substr(0, 90) + "..." : title;
		
					// Generate HTML for the current item
					let html = `<div class="col-sm-4 item-card"><div class="card text-left">`;
					html += me.get_image_html(item, title);
					let count_html = `<div class="cart-indicator ${item.in_cart ? '' : 'hidden'}" data-item-code="${item.item_code}">${item.item_count}</div>`;
		
					html += me.get_card_body_html(item, title, me.settings, count_html); // Pass item count to get_card_body_html if needed
					html += `</div></div>`;
		
					// Append HTML to product wrapper
					$product_wrapper.append(html);
		
					// Attach click event to update count_html
					$product_wrapper.find(`#${item.name}.btn-add-to-cart-list`).click(function () {
						// Change the cart indicator to 1
						let $indicator = $product_wrapper.find(`.cart-indicator[data-item-code="${item.item_code}"]`);
						$indicator.text(1).removeClass('hidden');
						$(this).addClass('hidden'); // Hide "Add to Cart" button after clicking
						$(this).siblings('.go-to-cart').removeClass('hidden'); // Show "Go to Cart" button
					});
				});
			}).catch(err => {
				console.error("Error fetching item counts:", err);
			});
		}
		
		get_image_html(item, title) {
			let image = item.website_image;
	
			if (image) {
				return `
					<div class="card-img-container">
						<a href="/${ item.route || '#' }" style="text-decoration: none;">
							<img itemprop="image" class="card-img" src="${ image }" alt="${ title }">
						</a>
					</div>
				`;
			} else {
				return `
					<div class="card-img-container">
						<a href="/${ item.route || '#' }" style="text-decoration: none;">
							<div class="card-img-top no-image">
								${ frappe.get_abbr(title) }
							</div>
						</a>
					</div>
				`;
			}
		}
	
		get_card_body_html(item, title, settings, count_html) {
			let body_html = `
				<div class="card-body text-left card-body-flex" style="width:100%">
					<div style="margin-top: 1rem; display: flex;">
			`;
			body_html += this.get_title(item, title);

			// get floating elements
			if (!item.has_variants) {
				if (settings.enable_wishlist) {
					body_html += this.get_wishlist_icon(item);
				}
				if (settings.enabled) {
					body_html += this.get_cart_indicator(item, count_html);
				}

			}
	
			body_html += `</div>`;
			body_html += `<div class="product-category" itemprop="name">${ item.item_group || '' }</div>`;
	
			if (item.formatted_price) {
				body_html += this.get_price_html(item);
			}
	
			body_html += this.get_stock_availability(item, settings);
			body_html += this.get_primary_button(item, settings);
			body_html += `</div>`; // close div on line 49
	
			return body_html;
		}
	
		get_title(item, title) {
			let title_html = `
				<a href="/${ item.route || '#' }">
					<div class="product-title" itemprop="name">
						${ title || '' }
					</div>
				</a>
			`;
			return title_html;
		}
	
		get_wishlist_icon(item) {
			let icon_class = item.wished ? "wished" : "not-wished";
			return `
				<div class="like-action ${ item.wished ? "like-action-wished" : ''}"
					data-item-code="${ item.item_code }">
					<svg class="icon sm">
						<use class="${ icon_class } wish-icon" href="#icon-heart"></use>
					</svg>
				</div>
			`;
		}
	
		get_cart_indicator(item, count_html) {
			return count_html;
		}
	
		get_price_html(item) {
			let price_html = `
				<div class="product-price" itemprop="offers" itemscope itemtype="https://schema.org/AggregateOffer">
					${ item.formatted_price || '' }
			`;
	
			if (item.formatted_mrp) {
				price_html += `
					<small class="striked-price">
						<s>${ item.formatted_mrp ? item.formatted_mrp.replace(/ +/g, "") : "" }</s>
					</small>
					<small class="ml-1 product-info-green">
						${ item.discount } OFF
					</small>
				`;
			}
			price_html += `</div>`;
			return price_html;
		}
	
		get_stock_availability(item, settings) {
			if (settings.show_stock_availability && !item.has_variants) {
				if (item.on_backorder) {
					return `
						<span class="out-of-stock mb-2 mt-1" style="color: var(--primary-color)">
							${ __("Available on backorder") }
						</span>
					`;
				} else if (!item.in_stock) {
					return `
						<span class="out-of-stock mb-2 mt-1">
							${ __("Out of stock") }
						</span>
					`;
				}
			}
	
			return ``;
		}
	
		get_primary_button(item, settings) {
			if (item.has_variants) {
				return `
					<a href="/${ item.route || '#' }">
						<div class="btn btn-sm btn-explore-variants w-100 mt-4">
							${ __('Explore') }
						</div>
					</a>
				`;
			} else if (settings.enabled && (settings.allow_items_not_in_stock || item.in_stock)) {
				return `
					<div id="${ item.name }" class="btn
						btn-sm btn-primary btn-add-to-cart-list
						w-100 mt-2 ${ item.in_cart ? 'hidden' : '' }"
						data-item-code="${ item.item_code }">
						<span class="mr-2">
							<svg class="icon icon-md">
								<use href="#icon-assets"></use>
							</svg>
						</span>
						${ settings.enable_checkout ? __('Add to Cart') :  __('Add to Quote') }
						<div class="cart-indicator ${item.in_cart ? '' : 'hidden'}" data-item-code="${ item.item_code }"> 1 </div>
					</div>
	
					<a href="/cart">
						<div id="${ item.name }" class="btn
							btn-sm btn-primary btn-add-to-cart-list
							w-100 mt-4 go-to-cart-grid
							${ item.in_cart ? '' : 'hidden' }"
							data-item-code="${ item.item_code }">
							${ settings.enable_checkout ? __('Go to Cart') :  __('Go to Quote') }
						</div>
					</a>
				`;
			} else {
				return ``;
			}
		}
	}
});

